.App {
  text-align: center;
}

body {
  /* background-color: #f3f3f3 !important; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.list-bg {
  background-color: gainsboro !important;
}
.center-text {
  text-align: center;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-6 {
  margin-top: 6rem !important;
}
.count-card {
  border: 1px solid #42a6fc;
}
.list-btn {
  font-size: 11px;
  text-transform: initial;
}
.full-width {
  width: 100%;
}
.form-mini-btn {
  width: 150px;
}
.no-text-decoration {
 text-decoration: none;
}
.available-height {
  height: -webkit-fill-available;
}
.white-text {
  color: #fff !important;
}
.center-item {
  align-items: center;
}

.table-btn {
  font-size: 9px !important;
}
.navbar-layout {
  background-color: #074173;
}
.iconStyle {
  color: #42a6fc !important;
}
.admin-app-title {
  font-family: cursive;
    font-size: 18px;
    font-variant: small-caps;
    font-weight: bold;
    color: #25274d;
}
.admin-login-app-title {
  font-family: cursive !important;
    font-weight: bold !important;
    color: #25274d;
    background: linear-gradient(to right, #42a6fc 0%, #074173 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.common-app-bg {
  background-color: #074173 !important;
}
.admin-signin-box {
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
    padding: 1rem;
    border-radius: 20px;
}
.hor-ver-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-user-block {
  width: 140px;
  text-align: -webkit-center;
  background-color: #074173;
}
.nav-user-block p {
  font-size: 12px;
}
.nav-user-block-avatar {
  text-align: center;
  font-size: 30px !important;
  margin-top: 7px;
}
.even-space {
  display: flex;
  justify-content: space-evenly;
}
.between-space {
  display: flex !important  ;
  justify-content: space-between;
}
.around-space {
  display: flex;
  justify-content: space-around;
}
.center-space {
  display: flex;
  justify-content: center;
}
.log-type-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.nav-wapp-block {
  display: flex;
  border: 1px solid #fff;
  background-color: #fff;
  color: #074173;
  border-radius: 10px;
}
.nav-wapp-block:hover {
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}
.nav-wapp-img-block {
  text-align: center;
  width: 50px;
}
.nav-wapp-detail-block {
  width: 153px;
  border-left: 1px solid #d2d2d2;
  text-align: center;
}
.nav-wapp-detail-block label {
  font-size: 10px;
}
.nav-wapp-detail-block p {
  margin-top: 0;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 0;
}
.nav-wapp-img-block img {
  margin-top: 0.6rem;
}
.trans-nav-menu-block {
  background-color: rgb(0 0 0 / 40%);
}
.primary-nav-menu-block {
  background-color: #074173;
}
.nav-menues-block {
  /* border-bottom: 1px solid #074173; */
  width: 100%;
}
.nav-menues-block ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
}
.nav-menues-block ul > li {
  flex-grow: 1;
  border-right: 1px solid #42a6fc;
  text-align: center;
  position: relative;
  font-size: 12px;
}
.nav-menues-block ul > li:last-child {
  border-right: none;
}
.nav-menues-block div.menu-section {
  /* width: 66%; */
  width: 80%;
}
.main-menu {
  cursor: pointer;
  color: #fff;  
  /* font-weight: 600; */
  text-decoration: none;
}
.main-menu:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 20%;
  right: 0;
  width: 60%;
  height: 2px;
  background-color: #42a6fc;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: opacity 300ms, transform 300ms;
}
.main-menu:hover,
.main-menu:focus {
  color: #fff;
}

.main-menu:hover::after,
.main-menu:focus::after {
  opacity: 1;
  transform: scale(1);
}


.app-header {
  background-color: transparent !important;
  border-bottom: 1px solid #99999945;
}
.sign-type-divider {
  margin-top: -2px;
  content: none;
  border-left: 2px solid;
  color: #42a6fc;
}

.slide-record {
/* background-color: #25274d; */
/* color: #fff; */
}
.main-slider-block {
  margin-top: -10px;
}


.whycm-logo {
  font-size: 4rem;
  color: #074173;
}

.cat-card{
  background-color: #100326 !important;
  border-radius: 24px !important;
  cursor: pointer;
}
.cat-card h4 {
  font-weight: bold;
  font-size: 12px;
  color: #fff;
}

.shadow-effect {
  box-shadow: -11px 11px 1px rgba(0, 0, 0, 0.3);
}

.circle-image {
  /* border-radius: 50%; */
  transition: transform .2s;
}

.trans-logo-block {
  background-color: rgb(253 253 253 / 75%);
}
.white-bg {
  background-color: #fff;
}

.logo-block {
  /* width: 300px; */
  text-align: center;
  cursor: pointer;
  width: auto;
  min-width: 139px;
}
.logo-block img {
  margin-top: 8px;
}

.main-icons {
  fill: #42a6fc !important;
}
.main-app-block {
  top: 0;
  position: absolute;
  z-index: inherit;
}
.main-app-block img {
  width: 100%;
}
.nav-menu-sections {
  display: flex;
  /* justify-content: space-around; */
  justify-content: space-evenly;
  align-items: center;
  margin-right: 20px;
  padding: 0px;
}
div > .MuiToolbar-root {
  min-height: 10px !important;
}

.slick-next:before {
  color: #42a6fc !important;
}
.slick-prev:before {
  color: #074173 !important;
}

.cart-count span {
  background-color: #fff;
  color: #42a6fc;
}

.auth-divider {
  border-left: 3px solid #cdcccc;
  height: 332px;
  opacity: 0.1;
  margin-left: 4rem;
}
.how-to-order {
  background: #d5d4d4;  
  width: 100%;
  padding: 5rem 0 2rem 0;
}
.how-to-order .title {
  text-align: center;
  text-transform: capitalize;
}
#app-footer {
  width: 100%;
  bottom: 0;
  position: relative;
  /* inherit */
}
.quick-link-block p {
  text-align: center;
    font-weight: bold;
    margin-top: 2.5rem;
}
.quick-link-block ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}
.quick-link-block ul > li {
  flex-grow: 1;
  border-right: 1px solid #000000;
  text-align: center;
  position: relative;
  font-size: 12px;
}
.quick-link-block ul > li:last-child {
  border-right: none;
}
.dynamic-content {
  /* top: 0;
  position: absolute; */
  width: 100%;
  margin-top: -10px;
  margin-bottom: 2rem;
}
.shop-cat h5 {
  background: antiquewhite;
    padding: 10px;
    font-size: 15px;
    text-align: center;
}

.ship-sec {
  border: 1px solid #b7b4b4;
  padding: 8px;
  margin: 1.5rem 0 1.5rem 0;
  border-radius: 10px;
}
.ship-sec h5 {
  margin: 0;
  border-bottom: 1px solid #b7b4b4;
}
.ship-sec p {
  font-size: 13px;
}

.whatsapp-chip {
  margin-top: 5px;
}
.whatsapp-chip .MuiChip-filled {
  background-color: green;
  color: #fff;
  cursor: pointer;
}
.whatsapp-chip > a > div > svg {
  color: #fff !important;
}

.view-all {
  color: #fff !important;
  border-color: #fff !important;
  /* margin-top: 3px !important; */
  font-size: 10px !important;
}
.payment-desc {
  font-size: 15px;
}

.gpay-card {
  background: black;
  color: #fff;
  padding: 27px;
  border-radius: 10px;
}

#page-to-top {
  position: fixed;
  bottom: 5%;
  right: 2%;
  z-index: 1000;
  background-color: #42a6fc;
  color: white;
}

#side-info {
  width: auto;
  position: fixed;
  bottom: 54%;
  right: 0;
  text-align: center;
  z-index: 1000;
  min-width: 43px;
  border-radius: 10px;
}
#side-info div#whatsapp {
  background: green;
  color: #fff;
  padding: 5px;
  border-radius: 10px 0px 0px 0px;
  border: 1px solid #bababe;
}
#side-info div#whatsapp > a {
  color: #fff;
}
#side-info div#whatsapp > a > svg {
  margin-top: 5px;
}
#side-info div#cart {
  background: #42a6fc;
  padding: 5px;
  color: #fff;
  border-radius: 0px 0px 0px 10px;
  border: 1px solid #bababe;

}
#side-info div#cart > a {
  color: #fff;
  text-decoration: none;
}
#side-info div#cart > a > svg {
  font-size: 16px;
}
#side-info div#cart > a > h2 {
  margin: 0;
  font-size: 13px;
}


.fee-splitup {
  background: #074173;
  padding: 1rem;
  border-radius: 23px;
  color: #fff;
  margin-top: 2rem;
}
.fee-splitup table {
  width: 100%;
}
.fee-splitup table tbody tr:nth-child(2) td{
  border-bottom: 2px solid #42a6fc;
}
#shop_with_us_head h3 {
  color: #fff;
}

.mapouter{position:relative;text-align:right;width:100%;height:100%;}
.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:100%;}
.gmap_iframe {width:100% !important;height:308px!important;}
.footer-menu-sec {
  padding: 10px;
}
.footer-menu-sec h6 {
  font-size: 18px;
  /* font-weight: bold; */
}
.footer-menu-sec ul{
  list-style: none;
  padding-left: 0;
}
.footer-menu-sec ul li {
  line-height: 46px;
  font-size: 14px;
  cursor: pointer;
  color: #000;
}
.footer-menu-sec ul li a {
  text-decoration: none;
  color: #000;
}
#copyright-sec {
  padding: 1rem;
}
#copyright-sec p{
  text-align: center;
}
#copyright-sec b {
  color: #074173;
} 
#copyright-sec a {
  text-decoration: none;
}

#no-rec-block {
  background: darkgray;
  text-align: center;
  padding: 0.5rem;
}

.social-icons {
  background-color: #074173 !important;
  color: #fff !important;
  margin: 0 10px 0 10px !important;
}

#footer-sec {
  background-color: #fafafa;
}


.testimoni-card {
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #5296d1 8%, #08c450 83%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #5296d1 8%, #08c450 83%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #5296d1 8%, #08c450 83%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#5296d1', endColorstr='#08c450', GradientType=1);
  /* background: #f9f9f9; */
  padding: 2rem;
  min-height: 208px;

  display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    /* height: 400px !important; */
}
#testi-head p {
  margin-top: 0;
  font-size: 13px;
}

.review-card {
  box-shadow: 2px 8px 15px rgb(0 0 0 / 30%);
  padding: 1rem;
  margin: 1rem;
  border-radius: 18px;
}
#footer-app-info {
  text-align: justify;
  padding: 10px;
}
#footer-app-info p{
  margin: 0;
  font-size: 11.6px;
}
#footer-main-sec {
  background-color: rgb(151 152 164);
  padding-bottom: 24px;
}

.float-right {
  float: right;
}

.p-1r {
  padding: 1rem;
}


.bar-icon {
  float: right;
  color: #fff !important;
  height: 55px;
}

#mob-menu-head {
  text-align: center;
  padding: 0.5rem;
}
#mob-menu-top-head h4{
margin-left: 0.5rem;
}
#mob-menu-top-head button {
margin-top: 4px;
}

/* Responsive */
/* #mob-menu-top-head {
  display: none;
} */

.banner-card-header span {
  font-size: 12px;
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
  .nav-menu-sections {
    display: none !important;
  }
  .logo-block {
    display: none !important;
  }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {
  #mob-menu-top-head {
    display: none !important;
  }  
  .banner-card-header span {
    font-size: 15px;
  }
}