.product-container {
    width: 192px;
    /* height: 500px; */
    margin: 30px auto;
    padding-top: 1px;
  }
  .simple-bg {
    background-color: #e8e7e7;
  }
.product-card {
    border-radius: 25px;
    box-shadow: -11px 11px 1px rgba(0, 0, 0, 0.3);
  }
  .simple-card-head {
    position: relative;
    height: 221px;
    text-align: -webkit-center;
    border-bottom: 1px solid #e1dcdc;
  }
  
  .product-card-head {
    position: relative;
    padding: 1px 0 10px 0;
    /* height: 138px; */
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #5296d1 8%, #08c450 83%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #5296d1 8%, #08c450 83%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #5296d1 8%, #08c450 83%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#5296d1', endColorstr='#08c450', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    border-radius: 25px 25px 0 0;
    text-align: -webkit-center;
  }
  
  .product-card-logo {
    width: 55px;
    margin: 20px;
  }
  
  .product-img {
    margin-top: 19px;
    width: 60%;
    transition: transform .2s;
  }
  .hover-img:hover {
    transform: scale(1.5);
  }
  
  .product-detail {
    padding: 0 20px;
    font-size: 11px;
    color: #fff;
  }
  
  .product-detail h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 2px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }
  
  .back-text {
    display: inline-block;
    font-size: 125px;
    font-weight: 900;
    margin-left: -7px;
    margin-top: -12px;
    opacity: 0.1;
  }
  .simple-card-body {
    height: 220px;
  }
  
  .product-card-body {
    height: 200px;
    background: #fff;
    border-radius: 0 0 25px 25px;
    /* margin-top: 22px; */
  }
  
  .product-title {
    padding: 6px 0px 0px 8px;
    display: block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .product-title b {
    font-weight: 900;
    letter-spacing: 0px;
  }
  
  .badge {
    font-size: 10px;
    font-weight: 300;
    color: #fff;
    background: #074173;
    padding: 5px 4px;
    border-radius: 4px;
    margin: 2rem;
  }
  
  .product-caption {
    display: block;
    padding: 0 9px;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .product-rating {
    padding: 0 20px;
    font-size: 11px;
  }
  
  .product-rating i.grey {
    color: #acacab;
  }
  
  .product-size h4 {
    font-size: 14px;
    padding: 0 11px;
    margin-top: 7px;
  }
  
  .ul-size {
    margin-left: 15px;
  }
  
  .ul-size li {
    list-style: none;
    float: left;
    margin-right: 20px;
  }
  
  .ul-size li a {
    display: inline-block;
    text-decoration: none;
    font-size: 11px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    text-align: center;
    line-height: 23px;
    color: #000;
  }
  
  .ul-size li a.active {
    background: #f35e3d;
    color: #fff;
  }
  
  .product-size:before,
  .product-size:after {
    content: '';
    display: block;
    clear: both;
  }
  
  .product-color {
    text-align: -webkit-center;
  }

  .product-color h4 {
    font-size: 11px;
    padding: 0 21px;
    margin-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
  }
  
  .ul-color {
    margin-left: 27px;
  }
  
  .ul-color li {
    list-style: none;
    float: left;
    margin-right: 20px;
  }
  
  .ul-color li a {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
  }
  
  .ul-color li a.orange {
    background: #f35e3d;
  }
  
  .ul-color li a.green {
    background: #11e95b;
  }
  
  .ul-color li a.yellow {
    background: #ffd414;
  }
  
  .ul-color li a.active:after {
    position: absolute;
    content: '';
    display: inline-block;
    border: 1px solid #f35e3d;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-left: -5px;
    margin-top: -5px;
  }
  
  .product-price {
    position: absolute;
    background: #11e95b;
    padding: 7px 20px;
    text-align: center;
    display: inline-block;
    font-size: 24px;
    font-weight: 200;
    color: #fff;
    border-radius: 7px;
    margin-top: -13px;
    margin-left: -5px;
    box-shadow: -10px 20px 15px -10px rgba(17, 233, 91, 0.3);
  }
  
  .product-price b {
    margin-left: 5px;
  }
  
  
  
  .yt
  {
    position: fixed;
    padding:7px 10px 3px 10px;
    top: 5px;
    right: 5px;
    background: rgba(0,0,0,0.1);
  }
  
  .yt:hover
  {
    background: rgba(0,0,0,0.2);
  }

  .brand-badge {
    width: auto;
    background: #074173;
    color: #fff;
    padding: 7px;
    border-radius: -18px;
    font-size: 9px;
    margin-top: -0.7rem;
    position: absolute;
    left: 0;
    border-radius: 7px;
  }
  .real-price {
    font-size: 20px;
    color: #074173
  }
  /* ---Product --- */

 
 
  
  .addtocart{
    display:block;
    padding:0.3em;
    border-radius:100px;
    border:none;
    font-size:2em;
    position:relative;
    /* background:#000; */
    cursor:pointer;
    width:90%;
    overflow:hidden;
    transition:transform 0.1s;
    z-index:1;
    /* border: 2px solid; */
    margin-top: 1rem;
    background-image: linear-gradient(to right, #1B4D3E , #018749);
    /* background-image: linear-gradient(to right, #08c450 , #5296d1); */
    box-shadow: 0px 8px 1px rgb(0 0 0 / 12%);
  }
  .pretext  p {
    margin: 0;
  }
  .pretext svg {
    background: #fff;
    color: #000;
    border-radius: 50%;
    padding: 3px;
  }
  .pretext{
    color:#fff;
    /* background:#000; */
    height:100%;
    width:100%;
    font-family: 'Quicksand', sans-serif;
    font-size: 15px;
  }
  i{
    margin-right:10px;
  }
  .done{
    background:#fff;
    position:absolute;
    width:100%;
    top:0;
    left:0;
    transition:transform 0.3s ease;
  
    transform:translate(-110%) skew(-40deg);
  }
  .posttext{
    /* background:#000; */
    height: -webkit-fill-available;
  }  
  .quantity-input {
    width: 70px;
    text-align: center;
    height: 30px;
  }
  .quantity-sec {
    margin: -6px 11px;
  }
  .quantity-sec > div {
    width: 100px;
  }
  .quantity-sec > div > label {
    font-size: 12px;
  }
  .inc-dec-btn {
    font-size: 20px !important;
    /* margin-top: -5px; */
  }
 .add-inc-btn-adj {
  margin-top: -5px;
 }
  /* Cart */  

.pdc-cat-type {
  border-radius: 10px;
}
.pdc-cat-header {
  padding: 17px;
  border-bottom: 2px solid #fff;
  border-radius: 16px 16px 0 0;
  text-align: center;
  color: #fff;
}

h4.view-all {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 10px 0 20px; 
  color: #000;
} 

h4.view-all span { 
   padding:0 10px; 
   cursor: pointer;
}
h4.view-all span:hover { 
  text-decoration: underline;
}

/*--------------- */
.new-arri-body {
  background-color: darkseagreen;
}
.new-arri-head{
  background: #4a994a;
}
.new-arri-v{
  background:#4a994a;
}

.hot-sell-body {
  background-color: steelblue;
}
.hot-sell-head{
  background: #235b8a;
}
.hot-sell-v{
  background:#235b8a;
}

.banner-cat-body {
  background-color: teal;
}
.banner-cat-head{
  background: #035050;
}
.banner-cat-v{
  background:#035050;
}

.content-center {
  text-align: -webkit-center;
}

